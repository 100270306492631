<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Ödeme İtirazları</b-card-title>
        </b-card-header>
        <template v-if="dataList.length > 0">
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            hover
          >
            <template #cell(company)="data">
              <div>
                <small class="text-primary">{{ data.item.onumber }}</small>
              </div>
              {{ data.item.company }}
            </template>
            <template #cell(amount)="data">
              <div>
                <div class="font-weight-bolder font-medium-2 text-danger">
                  {{ data.item.amount | toCurrency }} TL
                </div>
                <b-button
                  variant="flat-info"
                  pill
                  size="sm"
                  :href="'/payment-history/view/' + data.item.id_payments"
                  target="_blank"
                >
                  İşlemi Görüntüle
                </b-button>
              </div>
            </template>
            <template #cell(customer_user)="data">
              <div class="font-weight-bold">
                {{ data.item.customer_user }}
              </div>
              <div class="font-small-2 text-primary">
                Firma Kullanıcısı
              </div>
            </template>
            <template #cell(disapproval_statuses)="data">
              <div
                v-if="data.item.id_disapproval_statuses === '1'"
              >
                <b-badge variant="light-primary">
                  <feather-icon icon="ClockIcon" />
                  {{ data.item.disapproval_statuses }}
                </b-badge>
              </div>
              <div v-else-if="data.item.id_disapproval_statuses === '2'">
                <b-badge variant="light-success">
                  <feather-icon icon="CheckIcon" />
                  {{ data.item.disapproval_statuses }}
                </b-badge>
              </div>
              <div
                v-else-if="data.item.id_disapproval_statuses === '3'"
              >
                <b-badge variant="light-danger">
                  <feather-icon icon="XIcon" />
                  {{ data.item.disapproval_statuses }}
                </b-badge>
                <div>
                  <small class="text-danger">{{ data.item.cancel_content }}</small>
                </div>
              </div>
              <hr>
              <div class="font-small-3 text-muted">
                {{ data.item.content }}
              </div>
            </template>
            <template #cell(control)="data">
              <b-dropdown
                v-if="!userData.id_customers"
                text="İşlemler"
                variant="outline-primary"
                size="sm"
                :disabled="data.item.id_disapproval_statuses !== '1'"
                block
              >
                <b-dropdown-item
                  v-if="data.item.id_disapproval_statuses === '1'"
                  @click="acceptApproval(data.item.id)"
                >
                  <feather-icon
                    icon="CheckIcon"
                  />
                  Onayla
                </b-dropdown-item>
                <b-dropdown-item
                  v-if=" data.item.id_disapproval_statuses === '1'"
                  @click="declineModalShow(data.item.id)"
                >
                  <feather-icon icon="XIcon" />
                  Reddet
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCount"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </template>
        <template v-else>
          <b-alert
            variant="info"
            show
            class="ml-2 mr-2"
          >
            <div class="alert-body text-center">
              <div>
                <FeatherIcon
                  icon="InfoIcon"
                  size="16"
                />
              </div>
              <p class="lead">
                Kayıt bulunmuyor.
              </p>
            </div>
          </b-alert>
        </template>
      </b-card>
    </b-col>
    <b-modal
      v-model="disapprovalModal.status"
      title="İtiraz İptal Nedeni"
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <p>Ödemeye ait itiraz kaydı iptali için sebep veya açıklama belirtmeniz gereklidir.</p>
      <b-form-group
        label="Sebep veya Açıklama"
        label-for="cancel_content"
      >
        <b-form-textarea
          id="content"
          v-model="disapprovalModal.cancel_content"
          placeholder="Sebep veya Açıklama"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="disapprovalModal.status = false"
        >
          İptal
        </b-button>
        <b-button
          variant="success"
          :disabled="disapprovalModal.cancel_content === null"
          @click="declineApprove(disapprovalModal.disapprove)"
        >
          Kaydet
        </b-button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BCardHeader,
  BCardTitle,
  BAlert,
  BDropdown,
  BDropdownItem,
  BBadge,
  BButton, BFormGroup, BFormTextarea, BModal,
} from 'bootstrap-vue'

export default {
  name: 'DisapprovalPayments',
  components: {
    BModal,
    BFormTextarea,
    BFormGroup,
    BDropdownItem,
    BDropdown,
    BAlert,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BCardHeader,
    BCardTitle,
    BBadge,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      baseurl: this.$store.state.app.baseURL,
      currentPage: 1,
      perPage: 25,
      search: '',
      disapprovalModal: {
        disapprove: null,
        status: false,
        cancel_content: null,
      },
      fields: [
        {
          key: 'company',
          label: 'MÜŞTERİ',
          thClass: 'width-300 text-nowrap',
          tdClass: 'width-300',
        },
        {
          key: 'amount',
          label: 'İŞLEM TUTARI',
          thClass: 'width-100 text-center text-nowrap',
          tdClass: 'width-100 text-center text-nowrap',
        },
        {
          key: 'disapproval_statuses',
          label: 'DURUM',
        },
        {
          key: 'customer_user',
          label: 'TALEP EDEN',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },

        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'disapproval_payments.id as id',
          'disapproval_payments.content as content',
          'disapproval_payments.cancel_content as cancel_content',
          'customers.company as company',
          'payments.onumber as onumber',
          'payments.amount as amount',
          'disapproval_statuses.title as disapproval_statuses',
          'disapproval_payments.id_disapproval_statuses as id_disapproval_statuses',
          'disapproval_payments.id_payments as id_payments',
          'disapproval_payments.created as created',
          'customer_users.name as customer_user',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['disapprovalPayments/dataList']
    },
    dataCount() {
      return this.$store.getters['disapprovalPayments/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('disapprovalPayments/getDataList', this.dataQuery)
    },
    acceptApproval(idDisapprovalPayments) {
      this.$swal({
        title: 'Bildirimi Onayla',
        text: 'İtiraz kaydını onaylamak istiyor musunuz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('disapprovalPayments/saveData', {
            id: idDisapprovalPayments,
            id_disapproval_statuses: 2,
          })
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı',
                  text: 'İtiraz kaydı onaylandı.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    declineModalShow(item) {
      this.disapprovalModal.cancel_content = null
      this.disapprovalModal.status = true
      this.disapprovalModal.disapprove = item
    },
    declineApprove(idDisapprovalPayments) {
      this.disapprovalModal.status = false
      this.$swal({
        title: 'Bildirimi Reddet',
        text: 'İtiraz kaydını reddetmek istiyor musunuz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('disapprovalPayments/saveData', {
            id: idDisapprovalPayments,
            cancel_content: this.disapprovalModal.cancel_content,
            id_disapproval_statuses: 3,
          })
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.CancelResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
